import { MdOutlineChevronRight } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import { RiHome2Line } from "react-icons/ri";
import React from "react";

const Pageheading = ({ pageHeading, icon ,className}) => {
  return (
    <h1 className={`flex items-center text-white  font-montserrat leading-[2.19vw] gap-x-2 md:text-[0.94vw] text-[3vw] ${className}`}>
      <div className=" flex items-center  opacity-100">
        <span>{icon}</span>

        <MdOutlineChevronRight size={14} />
        <span>{pageHeading}</span>
      </div>
    </h1>
  );
};

export default Pageheading;
