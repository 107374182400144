import React, { useRef } from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import Button from "../Buttons/Button";
import "react-multi-carousel/lib/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "../HoverCard/Card";
import MobileSlider from '../CostomSlider/MobileSlider'

export const Latestrelease = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const video = [
    {
      id: 1,
      videoId: 538105209,
      url: "https://vimeo.com/538105209",
      thumbnail: "/images/2.png",
      // hunk
      src: "2.png",
      title: "/images/753845722_karmma_calling.png",
      content: "New Release",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Drama", "Thriller"],
      button: "watch Trailer",
    },
    {
      id: 2,
      videoId: 538102928,
      url: "https://vimeo.com/538102928",
      // vadilal
      thumbnail: "/images/1.png",
      src: "3.png",
      title: "/images/753845722_karmma_calling.png",
      content: "The Witcher: Blood Origin",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Explore the origins of the Witcher universe in this epic tale of magic and monsters.",
      genres: ["Drama", "Action"],
      button: "watch Trailer",
    },
    {
      id: 3,
      videoId: 333499420,
      url: "https://vimeo.com/333499420",
      thumbnail: "/images/4.png",
      src: "4.png",
      title: "/images/753845722_karmma_calling.png",
      content: "Spider-Man: A New Beginning",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 12+",
      description:
        "Peter Parker's journey continues as he takes on new villains and navigates the challenges of both his superhero and personal life.",
      genres: ["Action", "Adventure"],
      button: "Watch Trailer",
    },
    {
      id: 4,
      videoId: 290693752,
      url: "https://vimeo.com/290693752",
      thumbnail: "/images/6.png",
      // hyundai
      src: "6.png",
      title: "/images/753845722_karmma_calling.png",
      content: "Avatar: The Way of Water",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Jake Sully and Neytiri fight to protect their family from new threats in the lush and vibrant world of Pandora.",
      genres: ["Thriller", "Adventure"],
      button: "watch Trailer",
    },
    {
      id: 5,
      videoId: 278793691,
      url: "https://vimeo.com/278793691",
      // godrej
      thumbnail: "/images/8.png",
      src: "8.png",
      title: "/images/753845722_karmma_calling.png",
      content: "Karmma Calling",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Thriller", "Adventure"],
      button: "watch Movie",
    },
    {
      id: 6,
      videoId: 276703567,
      url: "https://vimeo.com/276703567",
      // vadilal friends
      thumbnail: "/images/2.png",
      src: "2.png",
      title: "/images/753845722_karmma_calling.png",
      content: "The Batman: Dark Knight Rises",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Batman faces his greatest challenge yet as he takes on a new adversary threatening Gotham City.",
      genres: ["Thriller", "Adventure"],
      button: "watch Movie",
    },
    // Add more objects as needed
  ];

  let Category = "";
  let categoryId;

  if (location.pathname.includes("/short-documentaries")) {
    Category = "short-documentaries";
    categoryId = 1;
  } else if (location.pathname.includes("/commercial")) {
    Category = "commercial";
    categoryId = 2;
  } else if (location.pathname.includes("/long-format")) {
    Category = "long-format";
    categoryId = 3;
  }

  console.log("Category:", Category);

  const handleViewAll = () => {
    if (Category) {
      navigate(`/${Category}/${categoryId}/latest-release`);
    }
  };

  return (
     <div className="w-full mx-auto " >
    <div className="flex items-center justify-between pt-[60px] md:pt-[8.7vh]  md:px-[6.77vw]">
      <div className="pl-[3vw] pr-[40vw] lg:pl-0 md:pr-0"><SectionHeading sectionheading={"latest release"} /></div>
      <span className="hidden md:block">
        <Button buttonText={"VIEW ALL"} onClick={handleViewAll} />
      </span>
    </div>
    <div className="relative md:pl-[6.77vw] hidden lg:flex flex-row overflow-x-hidden ">
     
        <Card  />
     
    </div>
    <div className=" lg:hidden pt-[40px] block relative">
      <MobileSlider/>
    </div>
  </div>
  );
};

export default Latestrelease;
