import React from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import Button from "../Buttons/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "../HoverCard/Card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import MobileSlider from '../CostomSlider/MobileSlider'
export const Documentary = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let Category = "";
  let categoryId;

  if (location.pathname.includes("/short-documentaries")) {
    Category = "short-documentaries";
    categoryId = 1;
  } else if (location.pathname.includes("/commercial")) {
    Category = "commercial";
    categoryId = 2;
  } else if (location.pathname.includes("/long-format")) {
    Category = "long-format";
    categoryId = 3;
  }

  const handleViewAll = () => {
    if (Category) {
      navigate(`/${Category}/${categoryId}/all`);
    }
  };

  return (
    <div className="w-full mx-auto " >
    <div className="flex items-center justify-between pt-[60px] md:pt-[8.7vh]  md:px-[6.77vw]">
      <div className="pl-[3vw] pr-[40vw] lg:pl-0 md:pr-0"><SectionHeading sectionheading={"All Shorts & Documentory"} /></div>
      <span className="hidden md:block">
        <Button buttonText={"VIEW ALL"} onClick={handleViewAll} />
      </span>
    </div>
    <div className="relative md:pl-[6.77vw] hidden lg:flex flex-row overflow-x-hidden ">
     
        <Card  />
     
    </div>
    <div className=" lg:hidden pt-[40px] block relative">
      <MobileSlider/>
    </div>
  </div>
  );
};

export default Documentary;
