import React, { useEffect, useState } from "react";
import "./Card.css";
import { BiRightArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
// import CustomPlaybutton from "../Buttons/CustomPlaybutton";
import "./Slider.css";

const Card = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(null);
  const [playTimeouts, setPlayTimeouts] = useState({});

  const [delay, setDelay] = useState(false);

  const movieCards = [
    {
      id: 1,
      videoId: 293940724,
      title: "Badlapur",
      genre: "Crime Drama Action",
      duration: "02:10 hrs",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      isVideo: true,
      backgroundImage:
        "url(https://qqcdnpictest.mxplay.com/pic/2b74d96e68091215201824d168077632/en/2x3/320x480/13919adc10506d3301e95fba39b438b1_1280x1920_badged_1668248090034.webp)",
      url: "https://vimeo.com/538105209",
      thumbnail: "/images/2.png",
      src: "1.png",
      content: "New Release",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Drama", "Thriller"],
    },
    {
      id: 2,
      videoId: 291681025,
      title: "Badlapur",
      genre: "Crime Drama Action",
      duration: "02:10 hrs",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      isVideo: true,
      backgroundImage:
        "url(https://qqcdnpictest.mxplay.com/pic/448f9063ac17c6f8d46443cf250a5706/en/2x3/320x480/e400a57549ac85cf6763c545353c88c2_1280x1920.webp)",
      url: "https://vimeo.com/538105209",
      thumbnail: "/images/4.png",
      src: "1.png",
      content: "New Release",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Drama", "Thriller"],
    },
    {
      id: 3,
      videoId: 333499420,
      title: "Badlapur",
      genre: "Crime Drama Action",
      duration: "02:10 hrs",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      isVideo: true,
      backgroundImage:
        "url(https://qqcdnpictest.mxplay.com/pic/6c2b9955377e4e8779cd29518e19b1da/en/2x3/320x480/eros_now_860647018c23aed516605bcad05d9496_badged_1668246682392.webp)",
      url: "https://vimeo.com/538105209",
      thumbnail: "/images/3.jpeg",
      src: "1.jpeg",
      content: "New Release",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Drama", "Thriller"],
    },
    {
      id: 4,
      videoId: 290693752,
      title: "Badlapur",
      genre: "Crime Drama Action",
      duration: "02:10 hrs",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      isVideo: true,
      backgroundImage:
        "url(https://qqcdnpictest.mxplay.com/pic/bc41cc6661e3c7b67bf54365cd17e629/en/2x3/320x480/dd95aea34cffdda42f0675e3552d7294_1280x1920.webp)",
      url: "https://vimeo.com/538105209",
      thumbnail: "/images/6.png",
      src: "6.png",
      content: "New Release",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Drama", "Thriller"],
    },
    {
      id: 5,
      videoId: 330036411,
      title: "Badlapur",
      genre: "Crime Drama Action",
      duration: "02:10 hrs",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      isVideo: true,
      backgroundImage:
        "url(https://qqcdnpictest.mxplay.com/pic/87a262c107ee442add9100a1c8ed2141/en/2x3/320x480/c6c92b164c51ff05b96740a47d796169_1280x1920.webp)",
      url: "https://vimeo.com/538105209",
      thumbnail: "/images/5.jpeg",
      src: "1.jpeg",
      content: "New Release",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Drama", "Thriller"],
    },
    {
      id: 6,
      videoId: 276703567,
      title: "Badlapur",
      genre: "Crime Drama Action",
      duration: "02:10 hrs",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      isVideo: true,
      backgroundImage:
        "url(https://qqcdnpictest.mxplay.com/pic/8e8f85ad5bafbbafcf4f21c6004f5b39/en/2x3/320x480/489596a2d20df588abf422040e295dba_1280x1920_badged_1671085514806.webp)",
      url: "https://vimeo.com/538105209",
      thumbnail: "/images/7.png",
      src: "1.png",
      content: "New Release",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Drama", "Thriller"],
    },
    {
      id: 7,
      videoId: 333499420,
      title: "Badlapur",
      genre: "Crime Drama Action",
      duration: "02:10 hrs",
      videoSrc:
        "https://media-content.akamaized.net/video/d1be0691bf91b4df93f492c4549d63ac/preview/1/16x9/320x180/h264_baseline.mp4",
      isVideo: true,
      backgroundImage:
        "url(https://qqcdnpictest.mxplay.com/pic/548c7467c1bf73078378a08747f65142/en/2x3/320x480/test_pic1663827358134.webp)",
      url: "https://vimeo.com/538105209",
      thumbnail: "/images/7.jpeg",
      src: "1.jpeg",
      content: "New Release",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Drama", "Thriller"],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of cards to show
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (isHovered !== null) {
      // Set delay to true after 5 seconds
      const delayTimeout = setTimeout(() => {
        setDelay(true);
      }, 2000);

      // Clean up timeout on component unmount or when hovered state changes
      return () => clearTimeout(delayTimeout);
    }
  }, [isHovered]);

  const handleMouseEnter = (id) => {
    setIsHovered(id);

    // Clear any existing timeout for this ID
    if (playTimeouts[id]) {
      clearTimeout(playTimeouts[id]);
    }

    // Reset the delay state
    setDelay(false);

    // Set a new timeout to play the video after 6 seconds
    const timeoutId = setTimeout(() => {
      const iframe = document.getElementById(`vimeo-video-${id}`);
      if (iframe) {
        iframe.contentWindow.postMessage(
          '{"event":"command","func":"playVideo","args":""}',
          "*"
        );
      }
    }, 6000); // 6000 milliseconds = 6 seconds

    setPlayTimeouts((prev) => ({ ...prev, [id]: timeoutId }));
  };

  const handleMouseLeave = (id) => {
    setIsHovered(null);
    setDelay(false);

    // Clear any existing timeout for this ID
    if (playTimeouts[id]) {
      clearTimeout(playTimeouts[id]);
      setPlayTimeouts((prev) => {
        const { [id]: _, ...rest } = prev;
        return rest;
      });
    }

    const iframe = document.getElementById(`vimeo-video-${id}`);
    if (iframe) {
      iframe.contentWindow.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        "*"
      );
    }
  };

  function handleWatchButton(e,id) { 
    e.stopPropagation(); 
    console.log("id", id);
    navigate(`/watch/${id}`);
  }

  function handleCardClick(id) {
    console.log("id", id);
    navigate(`/short-documentaries/details/${id}`);
  }

  return (
    <main className="md:pb-[10vh]">
      <section className="">
        <div className="main-box cursor-pointer ">
          {/* <Slider {...sliderSettings}> */}
          {movieCards.map((movie) => (
            <div
              className="box"
              style={{
                backgroundImage: `url(${movie.thumbnail})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              id={movie.id}
              key={movie.id}
              onClick={(e) => handleCardClick(movie.videoId)}
              onMouseEnter={() => handleMouseEnter(movie.id)}
              onMouseLeave={() => handleMouseLeave(movie.id)}
            >
              <div className="hide">
                <div className="hide-up">
                  {isHovered === movie.id ? (
                    <div
                      className="video-wrapper"
                      style={{
                        position: "relative",
                        width: "100%",
                        paddingBottom: "56.25%",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={movie.thumbnail}
                        alt=""
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          pointerEvents: "none",
                          zIndex: "1",
                        }}
                      />
                      {!delay && (
                        <div className="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center">
                          <button className="text-center flex items-center justify-center md:w-[4vw] relative w-10 h-full p-4 z-10">
                            <img
                              src="/icon/Play.svg"
                              alt="Play button"
                              className="w-full h-auto"
                            />
                          </button>
                        </div>
                      )}

                      {delay && (
                        <iframe
                          id={`vimeo-video-${movie.id}`}
                          src={`https://player.vimeo.com/video/${movie.videoId}?autoplay=1&muted=1&controls=0&title=0&byline=0&portrait=0`}
                          title="Vimeo video"
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          allowFullScreen
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            pointerEvents: "none",
                            zIndex: "2",
                          }}
                        ></iframe>
                      )}
                    </div>
                  ) : (
                    <img src={movie.thumbnail} alt={movie.title} />
                  )}
                </div>
                {isHovered === movie.id && (
                  <div className=" ">
                    <div className="absolute bottom-0 left-0 w-full h-auto  transition-all duration-500 ease-in-out flex flex-col justify-start items-start  text-white">
                      <div className="z-10 w-full flex flex-col items-start md:pb-[1.74vh] md:px-[0.83vw]">
                        <div className="flex items-center justify-start text-white text-xs md:text-[0.65vw] font-semibold   font-montserrat pb-[1vh] gap-x-[0.28vw] ">
                          <div>{movie.year}</div>
                          <div className="w-[0.21vw]  h-[0.32vh] opacity-50 rounded-full bg-white"></div>
                          <div>{movie.season}</div>
                          <div className="w-[0.21vw]  h-[0.32vh] opacity-50 rounded-full bg-white"></div>
                          <div>{movie.language}</div>
                          <div className="w-[0.21vw]  h-[0.32vh] opacity-50 rounded-full bg-white"></div>
                          <div className="text-white bg-[#36c31f69] rounded-[0.21vw] md:p-[3px]">
                            <span className="text-white whitespace-pre-line leading-none brightness-200">
                              {movie.rating}
                            </span>
                          </div>
                        </div>
                        <p className="hidden md:block font-montserrat !overflow-hidden opacity-100 font-light  text-white text-xs md:text-[0.65vw] pb-[1.57vh]  text-start leading-relaxed">
                          {movie.description}
                        </p>
                        <div className="w-full flex items-center justify-start pb-6 z-30">
                          <div
                            className="border-[0.03rem] border-white py-[1.61vh] px-[0.56vw] md:mt-2 rounded-[1.5vw] relative overflow-hidden group !cursor-pointer"
                            onClick={(e) => handleWatchButton(e,movie.videoId)}
                          >
                            <button className="flex items-center gap-2 text-white uppercase font-semibold opacity-100 relative z-10  font-montserrat text-xs md:text-[0.65vw]">
                              <span>Watch Trailer</span>
                              <div className="flex items-center justify-center bg-[#4AB66C] rounded-full  transition-colors duration-300 ease-in-out group-hover:bg-white">
                                <BiRightArrowAlt className="text-white w-[1.4vw] h-auto transition-transform duration-300 ease-in-out group-hover:text-[#22AD86] -rotate-45 group-hover:rotate-0" />
                              </div>
                            </button>
                            <div className="absolute inset-0 bg-custom-vertical-gradient transform translate-x-full group-hover:translate-x-0 transition-transform duration-500 ease-in-out"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
          {/* </Slider> */}
        </div>
      </section>
    </main>
  );
};

export default Card;
