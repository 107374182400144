import React from 'react'
import AboutPageHeading from './AboutPageHeading';
import MultipleRows from './MultipleRows';
export const Section4 = () => {
  return (
    <div className=' md:pl-[6.77vw] md:pr-[4.58vw]'>
       <div className="md:py-[5.56vh] ">
       <AboutPageHeading headingText="Our Partners" />
      </div>

     <div className=' '>
        <MultipleRows/>
     </div>

    </div>
  )
}

export default Section4
