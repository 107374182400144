import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from '../../assets/AboutUs/motor.png'
import img2 from '../../assets/AboutUs/wobby (1).png'
import img3 from '../../assets/AboutUs/autovision.png'

const images = [img1,img2,img3,img1,img1,img2,img3,img1];

export function Award() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,  // 4 columns
    slidesToScroll: 4, // Scroll 4 at a time
    rows: 2,          // 2 rows
    slidesPerRow: 1,  // 1 slide per row
    centerMode: false, // Disable center mode
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          rows: 4,   
          slidesToShow: 1,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          rows: 4,   
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="md:pr-[4.58vw]  ">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="flex justify-center items-center pb-[4.44vh] md:pb-[4.44vh]">
            <div className="w-[91vw] h-[22.13vh] md:w-[20.68vw] md:h-[25.83vh] flex flex-col bg-cover bg-center overflow-hidden relative">
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-full object-fill filter grayscale hover:grayscale-0 transition duration-300 ease-in-out"
              />
             
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Award;
