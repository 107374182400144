import React from 'react';

export const AboutPageHeading = ({ headingText }) => {
  return (
    <div className="text-left">
      <h1 className="font-bebas text-white uppercase tracking-tight opacity-100 
        md:text-[3.54vw] text-[10vw] pl-[5vw] md:pl-0"
      >
        {headingText}
      </h1>
    </div>
  );
}

export default AboutPageHeading;
