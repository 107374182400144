import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

// Custom Next Arrow

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-next-arrow absolute right-1 top-1/2 opacity-50 transform -translate-y-1/2 z-10 cursor-pointer"
      onClick={onClick}
    >
      <FiChevronRight size={30} color="#ffff" /> {/* Custom right icon */}
    </div>
  );
};

// Custom Previous Arrow
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-prev-arrow absolute left-0 top-1/2 opacity-50 transform -translate-y-1/2 z-10 cursor-pointer"
      onClick={onClick}
    >
      <FiChevronLeft size={30} color="#ffff" />
    </div>
  );
};

const video = [
  { id: 1, thumbnail: "/images/7.png" , videoId: "293940724"},
  { id: 2, thumbnail: "/images/4.png",videoId: "291681025" },
  { id: 3, thumbnail: "/images/4.png" ,videoId: "333499420"},
  { id: 4, thumbnail: "/images/1.png",videoId: "291681025" },
  { id: 5, thumbnail: "/images/1.png" ,videoId: "293940724"},
  { id: 6, thumbnail: "/images/12.png",videoId: "276703567" },
  // Add more objects as needed
];

export function Responsive() {
    const navigate = useNavigate();
     const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 4,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3, // Show 3 cards on mobile
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className=" h-[24.54vh] mx-auto px-[2.4vw] pt-0"> {/* Adjust padding here */}
      <Slider {...settings}>
        {video.map((item) => (
          <div key={item.id} className="p-1 w-[18vw] "> {/* Adjust gap here */}
            <div className="bg-white  h-[22.54vh]  overflow-hidden shadow-lg" onClick={() => { navigate(`/watch/${item.videoId}`); }}
            >
              <img
                src={item.thumbnail}
                alt={`Thumbnail ${item.id}`}
                className="w-full h-full object-cover" 
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Responsive;
