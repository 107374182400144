// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import contactImg from "./contactpage/contactimg.png";
// import Heading from "../Heading";

import Heading from "../../components/Highlightedheading/Heading";
import Pageheading from "../../components/PageHeading/Pageheading";
import { RiHome2Line } from "react-icons/ri";
import texture from "./contactpage/texture.png";
import toast, { Toaster } from "react-hot-toast";
import BasicTextFields from "../../components/textfield/textField";
import Button from "../../components/Buttons/Button";
// import "./Contact.scss"; 
import "./contactus.css"

const ContactPage = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = (values) => {
    let errors = {};

    // Full Name Validation
    if (!values.fullName.match(/^[a-zA-Z\s]{3,}$/)) {
      errors.fullName = "Please enter a valid name with at least 3 characters.";
    }

    // Email Validation
    if (!values.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
      errors.email = "Please enter a valid email address.";
    }

    // Phone Number Validation
    if (!values.phoneNumber.match(/^[6789]\d{9}$/)) {
      errors.phoneNumber =
        "Please enter a valid 10-digit phone number starting with 6, 7, 8, or 9.";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formValues);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // No errors, show success message
      toast.success("Form submitted successfully!");

      // Clear form after submission
      setFormValues({
        fullName: "",
        email: "",
        phoneNumber: "",
        message: "",
      });
    }
  };

  return (
    <div className="w-full h-full  text-white relative">
      <Toaster position="top-right" />
      <div className="relative w-full md:h-[72.9vh] h-[77vh] flex items-center overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent z-10 h-[93dvh]">
          <div className="md:pl-[5.63vw] pl-[3vw] flex items-center md:pt-[21vh] pt-[15vh]">
            <Pageheading pageHeading={"Contact Us"} icon={<RiHome2Line />} />
          </div>
        </div>

        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center flex justify-center items-center z-0"
          style={{
            backgroundImage: `url(${contactImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center ", //
            width: "100%",
            height: "100%",
          }}
        >
          <Heading headingText={"Contact Us"} />
        </div>
      </div>

      <div className=" grid grid-cols-1 md:grid-cols-2 w-full px-4 md:pl-[7.95vw] md:py-[4.69vw] ">
        <div className=" flex flex-col">
          <h2 className="font-montserrat uppercase font-medium md:text-[1.88vw] text-[5.87vw] text-white  md:w-[30.42vw]  pt-[5.56vh] ">
            <span>PLEASE BE FREE TO </span>
            <br />
            <span>CONTACT US USING THE FORM</span>
            <br />
            <span className="">BELOW OR EMAIL DIRECTLY:</span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2   md:gap-x-[6.35vw] gap-y-[3.70vh] pt-[3.70vh] md:gap-y-[7.41vh] md:pt-[8.15vh] md:text-[1.25vw] text-[5.33vw] font-montserrat font-light">
            <div>
              <p className="flex items-center md:space-x-[0.833vw] space-x-[6.4vw]">
                <span className="md:w-[2.29vw] w-[10vw]">
                  <img
                    src="/Contactpage/maillogo.png"
                    className="w-full h-full object-cover"
                    alt=""
                  />
                </span>
                <span className="">Ashutosh:</span>
              </p>
              <a
                href="mailto:ashutosh@ratfilms.in"
                className="md:underline md:pl-[3.20vw] pl-[18vw]"
              >
                ashutosh@ratfilms.in
              </a>
            </div>
            <div>
              <p className="flex items-center md:space-x-[0.833vw] space-x-[6.4vw]">
                <span className="md:w-[2.29vw] w-[10vw] ">
                  <img
                    src="/Contactpage/maillogo.png"
                    className="w-full h-full object-cover"
                    alt=""
                  />
                </span>
                <span className="">Ruchi:</span>
              </p>
              <a
                href="mailto:ruchi@ratfilms.in"
                className="md:underline md:pl-[3.20vw] pl-[18vw]"
              >
                ruchi@ratfilms.in
              </a>
            </div>
            <div>
              <p className="flex items-center md:space-x-[0.833vw] space-x-[6.4vw] whitespace-nowrap">
                <span className="md:w-[2.29vw] w-[10vw]">
                  <img
                    src="/Contactpage/maillogo.png"
                    className="w-full h-full object-cover"
                    alt=""
                  />
                </span>
                <span className="">Information Desk:</span>
              </p>
              <a
                href="mailto:info@ratfilms.in"
                className="md:underline md:pl-[3.20vw] pl-[18vw]"
              >
                info@ratfilms.in
              </a>
            </div>
            <div>
              <p className="flex items-center md:space-x-[0.833vw] space-x-[6.4vw]">
                <span className="md:w-[2.29vw] w-[10vw]">
                  <img
                    src="/Contactpage/maillogo.png"
                    className="w-full h-full object-cover"
                    alt=""
                  />
                </span>
                <span className="whitespace-nowrap">Taher:</span>
              </p>
              <a
                href="mailto:taher@ratfilms.in"
                className="md:underline md:pl-[3.20vw] pl-[18vw]"
              >
                taher@ratfilms.in
              </a>
            </div>
            <div>
              <p className="flex items-center md:space-x-[0.833vw] space-x-[6.4vw] whitespace-nowrap">
                <span className="md:w-[2.29vw] w-[10vw]">
                  <img
                    src="/Contactpage/maillogo.png"
                    className="w-full h-full object-cover"
                    alt=""
                  />
                </span>
                <span className="">Accounts Department:</span>
              </p>
              <a
                href="mailto:accounts@ratfilms.in"
                className="md:underline md:pl-[3.20vw] pl-[18vw]"
              >
                accounts@ratfilms.in
              </a>
            </div>
          </div>
          <div className=" border-[1px] opacity-[15%] border-[#ffff] md:w-[50%] w-full md:my-[7.41vh] my-[3.70vh]"></div>
          <div className="flex flex-col md:flex-row items-start md:gap-x-[6.35vw] gap-y-[3.70vh] md:gap-y-0 justify-between font-montserrat font-light md:text-[1.25vw]  text-[5.33vw]">
            <div>
              <p className="flex items-start md:space-x-[0.833vw] space-x-[6.4vw]">
                <span className="md:w-[2.29vw] w-[10vw]">
                  <img
                    src="/Contactpage/phone.png"
                    className="w-full h-full object-cover"
                    alt=""
                  />
                </span>
                <span className="">Telephone directly via </span>
              </p>
              <a
                href="tel:9029500400"
                className="md:underline md:pl-[3.20vw] pl-[18vw]"
              >
                9029500400
              </a>
            </div>
            <div>
              <p className="flex items-start  md:space-x-[0.833vw] space-x-[6.4vw] ">
                <span className="md:w-[2.29vw] w-[32.29vw]">
                  <img
                    src="/Contactpage/location.png"
                    className="w-full h-full object-cover"
                    alt=""
                  />
                </span>
                <div className="md:w-[16.72vw]  md:pl-0">
                  {" "}
                  305 Suvaas, 15th Road, Near Red Chillies Entertainment /
                  Starbucks Santacruz West. Mumbai.
                </div>
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:items-end items-start md:pr-[5.68vw] md:gap-y-[6.11vh]">
          <span className="font-montserrat underline text-[5.33vw] pt-[5vh] font-light md:hidden block">
            Get Direction
          </span>
          <div
            className="flex md:flex-row items-end flex-row-reverse md:space-x-[0.833vw] gap-x-[3.4vw] md:gap-x-0  py-[3.77vh] md:py-0 cursor-pointer relative"
            onMouseEnter={() => setIsHovered(true)}
          >
            {/* Mail Icon with Smooth Animation */}
            <a
              href="mailto:example@example.com"
              className={`md:w-[2.97vw] w-[10vw] ${
                isHovered
                  ? "opacity-100 translate-x-0"
                  : "opacity-0 translate-x-8"
              } transform transition-transform transition-opacity duration-[1.5s] ease-in-out`}
            >
              <img src="/Contactpage/mail.png" alt="mail" />
            </a>

            {/* Link Icon with Smooth Animation */}
            <a
              href="https://example.com"
              className={`md:w-[2.97vw] w-[10vw] ${
                isHovered
                  ? "opacity-100 translate-x-0"
                  : "opacity-0 translate-x-8"
              } transform transition-transform transition-opacity duration-[1.5s] ease-in-out`}
            >
              <img src="/Contactpage/link.png" alt="link" />
            </a>

            {/* Share Icon and Text */}
            <div className="flex items-center">
              <span className="md:w-[2.97vw] w-[10vw]">
                <img
                  src="/Contactpage/share.png"
                  alt="share"
                  className="w-full h-full object-cover"
                />
              </span>
              <span
                className={`${
                  isHovered ? "opacity-100" : "opacity-0"
                } transition-opacity duration-700 font-montserrat font-light md:text-[0.9375vw] md:pl-3 pl-1 `}
              >
                share
              </span>
            </div>
          </div>   



          <div className="flex items-center  justify-center w-full shadowanimation2 rounded-md">
          <div className="flex items-center justify-center w-full shadowanimation1 rounded-md"> 
          <div className="flex items-center justify-center w-full shadowanimation rounded-md"
          >
          <div className=" md:block hidden p-[1vh]">
            <img src="/Contactpage/map.png" alt="mail" />
          </div>  
          </div>
          </div> 
          </div> 


        </div>
      </div>

      <div
        className="relative flex flex-col w-full"
        style={{
          backgroundImage: `url(${texture})`,
          backgroundPosition: "0% 0%",
          backgroundSize: "cover",
        }}
      >
        <div className="outer-scratch  rounded-lg w-full h-full flex flex-col font-montserrat font-light text-white relative">
          <div className="inner-scratch">
            <div className="background grain md:px-[7.29vw] px-4  md:pb-[17.41vh] ">
              <div className="md:py-[9.26vh] text-center">
                <h2 className="text-white text-[13.87vw] md:text-[3.54vw] font-bebas_neue tracking-normal uppercase brightness-125 my-[3.7vh] md:my-0">
                  ENQUIRE NOW
                </h2>
              </div>

              <form
                onSubmit={handleSubmit}
                className="space-y-6 md:space-y-16 "
              >
                <div className="flex flex-col space-y-6 md:flex-row text-[16px] md:text-[1.25vw] justify-between items-center md:gap-10 md:space-y-0 md:space-x-4">
                  <div className="w-full">
                    <BasicTextFields
                      name="fullName"
                      value={formValues.fullName}
                      onChange={handleChange}
                      text="Name*"
                    />
                    {errors.fullName && (
                      <p className="text-red-500 text-md p-1">
                        {errors.fullName}
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <BasicTextFields
                      name="email"
                      value={formValues.email}
                      onChange={handleChange}
                      text="Email Id*"
                    />
                    {errors.email && (
                      <p className="text-red-500 text-md p-2">{errors.email}</p>
                    )}
                  </div>
                  <div className="w-full">
                    <BasicTextFields
                      name="phoneNumber"
                      value={formValues.phoneNumber}
                      onChange={handleChange}
                      text="Contact No*"
                    />
                    {errors.phoneNumber && (
                      <p className="text-red-500 text-md p-1">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-28">
                  <div className="w-full space-y-6">
                    <BasicTextFields
                      name="message"
                      value={formValues.message}
                      onChange={handleChange}
                      text="Message"
                      fullWidth={true}
                    />
                    {/* {errors.message && (
              <p className="text-red-500 text-md p-1">{errors.message}</p>
            )} */}
                  </div>
                  <Button
                    buttonText={"Submit"}
                    customCss={"md:pt-2 py-[3.7vh] md:py-0"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
