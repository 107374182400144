// eslint-disable-next-line no-unused-vars
import React from "react";
import contactImg from "./img/contactimg.png";
// import Heading from "../Heading";
import Heading from "../../components/Highlightedheading/Heading";
import SubCategoryItem from "./SubCategoryItem";
import Pageheading from "../../components/PageHeading/Pageheading";
import { RiHome2Line } from "react-icons/ri";

const SubCategoryPage = ({ headingText, pageHeading }) => {
  //628
  const arr = [1, 15, 16, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  return (
    <div className="w-full h-full  text-white">
      <div className="relative w-full h-[50vh] md:h-[72.8vh] flex items-center overflow-hidden">
        <div className="absolute inset-0  "></div>
        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center flex justify-center items-center"
          style={{ backgroundImage: `url(/Upcoming_img/up_bg.png)` }}
        >
          {/* <Heading headingText={headingText} className="flex items-center justify-center flex-wrap w-[50%]" /> */}
          <Heading
            headingText={headingText}
             className={" flex flex-wrap  text-center leading-none"}            
            
          />
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent z-10 h-[93dvh]">
          <div className="md:pl-[5.63vw] pl-[3vw] flex items-center md:pt-[21vh] pt-[14vh]">
            <Pageheading pageHeading={pageHeading} icon={<RiHome2Line />} className={" "} />
          </div>
        </div>
      </div>

      <div className="w-full  items-center  mx-auto md:py-[11.48vh] py-[4vh] flex justify-center">
        <div className="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 md:gap-[1.04vw] px-4 md:px-0 gap-[3.77vw] place-items-center ">
          {arr.map((item) => (
            <SubCategoryItem key={item} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubCategoryPage;
