import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function BasicTextFields({
  name,
  value,
  onChange,
  text,
  fullWidth,
  error, // Add error prop
}) {
  return (
    <Box
      component="div"
      className={`flex-grow ${fullWidth ? "w-full" : "w-auto"} text-white`}
      sx={{
        "& > :not(style)": { width: "100%" },
        zIndex: 10,
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id={`standard-basic-${name}`}
        label={text}
        name={name}
        value={value}
        onChange={onChange}
        variant="standard"
        fullWidth
        error={!!error} // Show error state
        helperText={error} // Display error message
        sx={{
          "& .MuiInputLabel-root": {
            color: "#FFFFFF !important", // Label color
            paddingLeft: "7px",
            fontFamily: "Montserrat", // Font family
            fontSize: {
              xs: "4.27vw",
               sm: "4.24vw",
              md:"1.24vw" 
               // Font size for larger screens
            },
            opacity: "0.8",
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: "white !important",
            opacity: "50% !important",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "white !important",
          },
          "& .MuiInputBase-input": {
            color: "white !important",
            fontSize: {
              xs: "4.27vw",
               // Font size for mobile screens
              sm: "3.24vw",
              md:"1.24vw" // Font size for larger screens
            },
            paddingLeft: "7px",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#FFFFFF !important",
            transform: "translate(0, 1.5px) scale(0.75)", // Move label up and scale down
          },
          "& .MuiInputLabel-shrink": {
            color: "#FFFFFF !important",
            transform: "translate(0, 1.5px) scale(0.75)", // Adjust label when it shrinks
          },
        }}
      />
    </Box>
  );
}
