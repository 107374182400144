// eslint-disable-next-line no-unused-vars
import React from "react";

// eslint-disable-next-line react/prop-types
const SubCategoryItem = ({ item }) => {
  return (
    <div className="md:w-[20.65vw] md:h-[44.81vh] w-full h-full transform transition-transform duration-300 ease-linear hover:scale-110 cursor-pointer hover:z-20">
      <img
        src={`/Upcoming_img/${item}.png`}
        alt=""
        className="w-full h-full object-cover"
      />
    </div>
  );
};

export default SubCategoryItem;
