// import React from "react";
import { useParams } from "react-router-dom";

const Watch = () => {
  const { videoId } = useParams();

  // Construct the Vimeo embed URL with parameters to hide like, share, and watch later options
  const videoUrl = `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&dnt=1&controls=1&transparent=0&autoplay=1&muted=0&buttons.like=0&buttons.watchlater=0&buttons.share=0`;

  return (
    <div className="relative w-screen h-screen bg-black">
      <iframe
        src={videoUrl}
        width="100%"
        height="100%"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{ position: "absolute", top: 0, left: 0 }}
        title="Vimeo Player"
      ></iframe>
    </div>
  );
};

export default Watch;
