import { useState, useEffect } from "react";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import FounderPerson from "./img/founderPerson.png";
import Ellipse from "./img/Ellipse 68.svg";
import glitchImg1 from "./img/1.png";
import glitchImg2 from "./img/2.png";
import glitchImg3 from "./img/3.png";
import glitchImg4 from "./img/4.png";
import glichImg from "./img/founderPerson.png"
import glitchImg5 from "./img/original photo.png";
import "./Faunder.css";

export default function FounderPersin({
  heading,
  flag,
  fName,
  lName,
  position,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentGlitch, setCurrentGlitch] = useState(FounderPerson);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // Glitch effect logic
  useEffect(() => {
    const glitchImages = [glichImg,glitchImg1,glichImg, glitchImg2,glichImg, glitchImg3,glichImg,glichImg,glichImg, glitchImg4,glichImg, glitchImg5];
    const originalImage = FounderPerson;
  
    let glitchTimeout;
    let revertTimeout;
  
    const applyGlitchEffect = () => {
      const randomImage = glitchImages[Math.floor(Math.random() * glitchImages.length)];
      const isFifthImage = randomImage === glitchImg5;
  
      setCurrentGlitch(randomImage);
  
      // If the fifth image is displayed, hold it for longer
      const holdTime = isFifthImage ? 1000 : 500; // 1 second for the fifth image, 0.5 seconds for others
  
      glitchTimeout = setTimeout(() => {
        // Revert to another random glitch image
        setCurrentGlitch(glitchImages[Math.floor(Math.random() * glitchImages.length)]);
        
        // Schedule the next glitch effect with a random delay
        revertTimeout = setTimeout(applyGlitchEffect, holdTime);
      }, 800); // 0.8 seconds to change the image
    };
  
    const startGlitchEffect = () => {
      setTimeout(() => {
        setCurrentGlitch(originalImage);
        applyGlitchEffect();
      }, 2000); // Initial wait of 1 second
    };
  
    startGlitchEffect();
  
    return () => {
      clearTimeout(glitchTimeout);
      clearTimeout(revertTimeout);
    };
  }, []);
  

  const shortText = `
    Ruchi Narain is a Multi Award winning Writer, Director, and Producer.
    She won the Filmfare award for writing Hazaaron Khwaishein Aisi and
    then made KAL (Yesterday & Tomorrow) which won the Choice Award. Since
    then she has been directing commercials, of which many have been
    clutter breakers and boosted the brand sales exponent as well as a
    successful multi-starrer campaign for the National Literacy Mission.
  `;

  const fullText1 = `
    Ruchi Narain is a Multi Award winning Writer, Director, and Producer.
    She won the Filmfare award for writing Hazaaron Khwaishein Aisi and
    then made KAL (Yesterday & Tomorrow) which won the Choice Award. Since
    then she has been directing commercials, of which many have been
    clutter breakers and boosted the brand sales exponent as well as a
    successful multi-starrer campaign for the National Literacy Mission.
  `;

  const fullText2 = `
    She has directed a vast range of commercials from Beauty to Kids to
    Electronics. She is adapting an American TV series for its official
    Indian remake for Life OK and is the Creative Producer.
  `;

  const fullText3 = `
    She is currently writing and directing Hanuman Da Damdaar, an
    animation feature produced by Percept as well as an untitled film for
    Dharma Productions. She is adapting an American TV series for its
    official Indian remake for Life OK and is the Creative Producer.
    Wanting to promote new talent, Ruchi started producing for younger
    directors as well. She has produced award-winning wildlife
    documentaries which were shown on Discovery Channel as well as shorts,
    music videos and commercials. A docu-feature for an NGO is also under
    production.
  `;

  return (
    <div
      className={`relative  w-full h-full px-6 md:px-[7.71vw] flex flex-col md:flex-row  py-12 md:py-[8.33vh] text-white ${
        flag ? "md:gap-x-[10.5rem]" : "md:flex-row-reverse"
      }`}
    >
      <div className="block md:hidden">
        {heading && <SectionHeading sectionheading={"OUR FOUNDERS "} />}
      </div>

      {/* Image Section */}
      <div className="pt-8 flex justify-center items-center h-full w-full md:w-[50%] relative order-1">
        <img
          src={Ellipse}
          alt="Ellipse"
          className="absolute inset-0 h-auto w-full top-[-5px] left-[-30px] z-0"
        />
        <img
          src={currentGlitch}
          alt="Ruchi Narain"
          className={`rounded-sm ml-12 mr-12 h-auto w-full md:w-[33.23vw] relative ${
            flag ? "transform scale-x-[1] md:scale-x-[-1] mr-0" : ""
          }`}
        />
        <div
          className={`absolute z-20 text-left transform -translate-x-1/2 -translate-y-1/2 ${
            flag
              ? "md:top-[78%] top-[78%] sm:top-[78%] right-[8%] md:right-[-9%]"
              : "top-[78%] left-[28%] md:left-[28%]"
          }`}
        >
          <h3 className="gradient-text text-[15vw] leading-none  md:text-5xl lg:text-6xl xl:text-7xl tracking-normal font-bebas_neue uppercase whitespace-nowrap">
            {fName}
           <br />
            {lName}
          </h3>
          <p className="uppercase pt-1 text-white opacity-70 text-[4vw] md:text-xl lg:text-2xl xl:text-2xl">
            {position}
          </p>
        </div>
      </div>

      {/* Content Section */}
      <div className="flex flex-col h-full w-full md:w-[60%] font-montserrat font-light order-2">
        <div className="w-full md:w-[100%] lg:w-[85%] xl:w-[85%]">
          <div className="hidden sm:block">
            {heading && <SectionHeading sectionheading={"OUR FOUNDERS "} />}
          </div>
          <div className="block md:hidden">
            <p className="opacity-80 font-montserrat font-light md:opacity-50 mt-1 text-[4vw] md:text-xs lg:text-sm xl:text-xl">
              {isExpanded ? fullText1 + fullText2 + fullText3 : shortText}
              <div
                onClick={handleToggle}
                className="cursor-pointer mt-2 text-white uppercase hover:underline"
              >
                {isExpanded ? "Read Less" : "Read More"}
              </div>
            </p>
          </div>
          <div className="hidden md:block font-montserrat md:mt-[10.46vh] md:w-[39.75vw]">
            <p className="md:text-[1.15vw] font-light md:leading-[1.88vw]">
              {fullText1}
            </p>
            <p className="md:text-[1.15vw] font-light md:leading-[1.88vw] md:pt-6 pt-2">
              {fullText2}
            </p>
            <p className="md:text-[1.15vw] font-light md:leading-[1.88vw] md:pt-6 pt-2">
              {fullText3}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
