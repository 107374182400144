import React from 'react'

const SectionHeading = ({sectionheading,CustomCss=""}) => {
  return (
    <h2 className={`text-white text-[12.8vw] md:text-[3.54vw]  font-bebas_neue md:leading-tight leading-none tracking-normal uppercase brightness-125 ${CustomCss}`}>
        {sectionheading} 
    </h2>
  )
}

export default SectionHeading
