import { useState, useEffect } from 'react';

// Custom hook for responsive styles
function useResponsiveDotsStyles() {
  const [dotsStyles, setDotsStyles] = useState({});

  useEffect(() => {
    const updateStyles = () => {
      const width = window.innerWidth;

      let newStyles = {
        bottom: '30px',
        left: '9.771vw',
      };

      if (width <= 768) {
        newStyles = {
          bottom: '100px',
          left: '80px',
        };
      }

      if (width <= 480) {
        newStyles = {
          bottom: '30px',
          left: '70px',
        };
      }

      setDotsStyles(newStyles);
    };

    // Initial style update
    updateStyles();

    // Update styles on window resize
    window.addEventListener('resize', updateStyles);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', updateStyles);
  }, []);

  return dotsStyles;
}

export default useResponsiveDotsStyles;
