import React from "react";
import AboutPageHeading from "./AboutPageHeading";
import img1 from "../../assets/AboutUs/Group 1700.png";
import ellipse from "../../assets/AboutUs/Ellipse 68.svg";

export const Section2 = () => {
  return (
    <div className="w-full h-full flex flex-col  md:pr-[4.58vw] md:pl-[6.77vw] sm:flex-col md:flex-row md:gap-x-[6.25vw]  md:pb-[12.04vh] md:pt-[8.33vh]">
      <div className="flex flex-col ">
        <div>
          <AboutPageHeading headingText="About Rat Films" />
        </div>

        <div className="relative flex flex-col items-center justify-center   md:hidden ">
          {/* Ellipse Image */}
          <img
            src={ellipse}
            alt="Ellipse"
            className="absolute left-[-30%] bottom-[-15%] z-0 scale-110 h-[600px] w-[600px] "
          />

          {/* Main Image */}
          <img
            src={img1}
            alt=""
            className="relative z-10 h-[47.41vh] w-[91.47vw]"
          />
        </div>

        <div className="flex flex-col  font-montserrat text-left  w-full md:w-[39.77vw] opacity-70 lg:opacity-100 pt-[5.56vh] px-[6vw] md:px-0 ">
          <p className="font-montserrat font-light text-[4vw]  md:text-[1.15vw] md:leading-[1.88vw] text-white opacity-100 mb-[4vh]">
            Passion, Ingenuity and Quality is what makes us stand apart from the
            rest. At RAT Films we believe in magic of <br />
            storytelling and compelling visuals. We believe even if it's your
            tenth film for the same brand it needs to be distinctive,
            <br /> compelling and true. It should be that 'One Film' which works
            for the brand on all levels.
          </p>
          <p className="font-montserrat font-light text-[4vw]  md:text-[1.15vw] md:leading-[1.88vw] text-white opacity-100 mb-[4vh]">
            RAT Films came into existence because of its commitment on
            delivering cutting edge films with hallmark of quality and <br />{" "}
            film making passion. RAT films are true to its core.
          </p>
          <p className="font-[Montserrat] font-light text-[4vw] md:text-[1.15vw]  md:leading-[1.88vw] text-white opacity-100 mb-[4vh]">
            We bring together some of the best names in the business to deliver
            exceptional quality films. We offer complete <br /> Start to Finish
            Solutions.
          </p>
        </div>
      </div>

      <div className="relative hidden md:flex flex-col items-center justify-center px-1">
        {/* Ellipse Image */}
        <img
          src={ellipse}
          alt="Ellipse"
          className="absolute left-[-40%] bottom-[5%] z-0"
          style={{ width: "50vw", height: "auto" }}
        />

        {/* Main Image */}
        <img
          src={img1}
          alt=""
          className="relative z-10 h-[73.8vh] w-[37.0vw]"
        />
      </div>
    </div>
  );
};

export default Section2;
