import { RxHamburgerMenu } from "react-icons/rx";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CgChevronDown } from "react-icons/cg";
import "./Header.css"; // Import the CSS file

const Header = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
  const [showNavbar, setShowNavbar] = useState(true);
  const [blurred, setBlurred] = useState(false);

  const handleSidebar = () => setOpenSidebar(!openSidebar);
  const closeSidebar = () => setOpenSidebar(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollPercentage =
        (scrollY /
          (document.documentElement.scrollHeight - window.innerHeight)) *
        100;

      // Show navbar when at the top of the page or if scroll percentage is above 17%
      setShowNavbar(scrollY < 50 || scrollPercentage > 17);
      setBlurred(scrollPercentage > 17);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    // Initial check to set navbar state based on initial scroll position
    handleScroll();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 w-full z-50 transition-transform duration-300 ease-in-out bg-custom-gradient ${
        showNavbar ? "translate-y-0" : "-translate-y-full"
      } ${blurred ? "navbar-blur" : "navbar-transparent"} bg-transparent`}
    >
      <div className="">
        <div
          className={`flex justify-between items-center w-full md:pl-[2.81vw] md:pr-[3.70vw] px-4  ${
            blurred ? "pb-[-40px] pt-2" : "pt-[3.70vh]"
          }`}
        >
          {/* Logo */}
          <div
            className={`flex relative items-center md:py-1 md:h-[13.5vh] overflow-visible gap-5 min-w-fit ${
              blurred ? " md:pt-5 " : "''"
            }`}
          >
            <Link to="/">
              <div className="">
                <img
                  // className="w-[25vw] h-[13vh] sm:w-[20vw] sm:h-[10vh] md:w-[15vw] md:h-[8vh] lg:w-[10vw] lg:h-[5vh] xl:w-[8vw] xl:h-[4vh] 2xl:w-[7vw] 2xl:h-[3.5vh]"
                  className="md:w-[14.32vw]  w-[26.93vw]  "
                  src="/logo/Logo.png"
                  alt="Logo"
                  loading="lazy"
                />
              </div>
            </Link>
          </div>

          {/* Menu for larger screens */}
          {isLargeScreen && (
            <nav>
              <ul
                className="flex items-center space-x-[2.29vw]  text-[1.04vw]  
             font-medium font-montserrat text-white whitespace-nowrap   "
              >
                <li>
                  <Link to="/commercial">Commercial</Link>
                </li>
                <div className="flex items-center">
                  <Link to="/long-format" className="flex items-center">
                    Long format{" "}
                    <span className="ml-1">
                      <CgChevronDown />
                    </span>
                  </Link>
                </div>
                <li>
                  <Link to="/short-documentaries">Short & Documentaries</Link>
                </li>
                <div className="flex items-center">
                  <Link to="/services" className="flex items-center">
                    Services{" "}
                    {/* <span className="ml-1">
                    <CgChevronDown />
                  </span> */}
                  </Link>
                </div>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/founders">Founders</Link>
                </li>
                <li>
                  <Link to="/media">Media</Link>
                </li>
                <li>
                  <Link to="/contactus">Contact Us</Link>
                </li>
              </ul>
            </nav>
          )}

          {/* Hamburger menu for smaller screens */}
          {!isLargeScreen && (
            <div className="flex items-center gap-5">
              <button
                className=" text-[7.5vw] font-thin text-[#FFFFFF]"
                onClick={handleSidebar}
                aria-label="Toggle Sidebar"
              >
                <RxHamburgerMenu />
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Sidebar for smaller screens */}
      <div className={`sidebar !overflow-hidden ${openSidebar ? "open" : ""}`}>
        <div className="sidebar-header">
          <Link to="/">
            <img className="max-w-[26.93vw]" src="/logo/Logo.png" alt="Logo" />
          </Link>
          <button
            className="sidebar-close-btn"
            onClick={closeSidebar}
            aria-label="Close Sidebar"
          >
            <img
              src="/icon/cross.svg"
              alt="crossicon"
              loading="lazy"
              className="w-[5vw]  object-cover"
            />
          </button>
        </div>
        <nav className="sidebar-menu  ">
          <Link to="/commercial" onClick={closeSidebar}>
            Commercial
          </Link>
          
            <Link to="/long-format" onClick={closeSidebar}>
           <div className="flex ">
           <div>
             Long format
             </div>
              <span className="ml-1 flex items-center justify-center">
                <CgChevronDown />
              </span>
           </div>
            </Link>
          
          <Link to="/short-documentaries" onClick={closeSidebar}>
            Short & Documentaries
          </Link>
          <Link to="/services" onClick={closeSidebar}>
            Services
          </Link>
          <Link to="/careers" onClick={closeSidebar}>
            Careers
          </Link>
          <Link to="/gallery" onClick={closeSidebar}>
            Gallery
          </Link>
          <Link to="/founders" onClick={closeSidebar}>
            Founders
          </Link>
          <Link to="/media" onClick={closeSidebar}>
            Media
          </Link>
          <Link to="/contactus" onClick={closeSidebar}>
            Contact Us
          </Link>
        </nav>
      </div>

      {/* Overlay to close sidebar */}
      {openSidebar && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={closeSidebar}
        ></div>
      )}
    </div>
  );
};

export default Header;
