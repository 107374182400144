import React from "react";
import Slider from "react-slick";
import "./Slider.css";
import img1 from "../../assets/SliderImg/11.png";
import img2 from "../../assets/SliderImg/12.png";
import img3 from "../../assets/SliderImg/13.png";
import img4 from "../../assets/SliderImg/14.png";
import img5 from "../../assets/SliderImg/15.png";
import Pageheading from "../PageHeading/Pageheading";
import { RiHome2Line } from "react-icons/ri";
import useResponsiveDotsStyles from "./ResponsiveDotsStyles"; // Import the custom hook

const dummyData = [
  { id: 1, imageUrl: img1 },
  { id: 2, imageUrl: img2 },
  { id: 3, imageUrl: img3 },
  { id: 4, imageUrl: img4 },
  { id: 5, imageUrl: img5 },
];

function SimpleSlider() {
  const dotsStyles = useResponsiveDotsStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: dotsStyles.bottom, // Use responsive styles here
          left: dotsStyles.left, // Use responsive styles here
          transform: "translate(-50%, -50%)", // Centering dots if needed
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <div className="slick-dot"></div>,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {dummyData.map((item) => (
          <div key={item.id} className="slide">
            <img
              src={item.imageUrl}
              alt={`Slide ${item.id}`}
              className="slide-image"
            />
          </div>
        ))}
      </Slider>
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent z-10 h-[93dvh]">
        <div className="md:pl-[5.63vw] flex items-center md:pt-[21.85vh]">
          <Pageheading pageHeading={"About Us"} icon={<RiHome2Line />} />
        </div>
      </div>
    </div>
  );
}

export default SimpleSlider;
