import { FiUpload } from "react-icons/fi";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

// Visually hidden input for file selection
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0, 0, 0, 0)',
  clipPath: 'inset(100%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// Styled Button component with custom styling
const CustomButton = styled(Button)(({ theme }) => ({
  width: '100%', // Make button full-width
  // maxWidth: '400px', // Optional: max width for better control
  color: "#FFFFFF !important", // Label color
  paddingLeft: "7px",
  fontFamily: "Montserrat", // Font family
  fontSize: "1.25vw", // Responsive font size
  opacity: "0.8",
  fontWeight: "inherit",
  backgroundColor: 'transparent', // Remove background color
  textTransform: 'none', // Disable uppercase text transformation
  padding: '10px', // Adjust padding for better spacing
  borderBottom: '0.5px dashed white', // Thinner white dashed underline
  display: 'flex', // Flexbox for proper alignment
  justifyContent: 'space-between', // Space between text and icon
  alignItems: 'center', // Center vertically
  '&:hover': {
    backgroundColor: 'transparent', // Ensure no background on hover
    borderBottom: '1px dashed white', // Thicker white dashed underline on hover
    opacity: "1",
  },
}));

const InputFileUpload = ({ text, onChange, value, ...rest }) => {
  const [fileName, setFileName] = React.useState(text);

  // Update fileName when the value prop changes
  React.useEffect(() => {
    if (value && value.name) {
      setFileName(value.name);
    } else {
      setFileName(text);
    }
  }, [value, text]);

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    onChange(file); // Pass the selected file to react-hook-form
  };

  return (
    <CustomButton
      component="label"
      role="button"
      variant="text" // Set variant to text to remove additional button styling
      tabIndex={-1}
      endIcon={<FiUpload />} // Use the custom upload icon at the end
      {...rest}
    >
      <div className="font-montserrat text-[4.27vw] md:text-[1.25vw]">
        {fileName}
      </div>
      <VisuallyHiddenInput
        type="file"
        onChange={handleFileChange}
        multiple={false} // Set to true if multiple files are allowed
      />
    </CustomButton>
  );
};

export default InputFileUpload;
