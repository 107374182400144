import { AiOutlineInstagram } from "react-icons/ai"; 
import { BsYoutube } from "react-icons/bs"; 
import { CgFacebook } from "react-icons/cg";
import { FaLinkedinIn } from "react-icons/fa";
import React from "react";
import { Link } from "react-router-dom";


export default function Footer() {
  return (
    <footer className=" px-4 pt-[6vh] md:pt-0 lg:mx-auto text-white relative">
      <div className="w-full flex flex-col lg:flex-row justify-between  lg:px-[7.29vw]   lg:pt-[9.26vh]">
        <div className="flex flex-col mb-6 lg:mb-0">
          <div className="hidden lg:block  items-start ">
            <img
              alt="RAT Films Logo"
              src="/logo/Logo.png"
              className="w-[11.98vw] "
            />
          </div>
          <p className="opacity-100 text-[4.7vw] lg:text-[1.25vw]  md:pt-[4vh] text-white font-montserrat  font-normal">
            305 Suvaas, 15th Road,
            <br />
            Near Red Chillies
            <br />
            Entertainment/Starbucks
            <br />
            Santacruz West, Mumbai.
          </p>
        </div>
        <div className="w-full lg:w-[17.23vw]   flex flex-col  lg:mb-0 py-[3.70vh] md:py-0 lg:mt-[3.96vh] ">
          <h2 className=" text-[5.33vw] lg:text-[1.25vw] font-semibold  text-[#22AD86] font-montserrat ">
            MORE INFORMATION
          </h2>
          <ul className=" lg:mt-[4.44vh] mt-[3vh] text-[#FFFFFF] font-montserrat  font-normal gap-y-[24px] text-[4.7vw] lg:text-[1.25vw]">
            <li className="">
              <Link to="/about">About Us</Link>
            </li>
            <li className="pt-[2.22vh]">
              <Link to="/services" className="">
                Services
              </Link>
            </li>
            <li className="pt-[2.22vh]">
              <Link to="/media" className="  ">
                Media
              </Link>
            </li>
            <li className="pt-[2.22vh]">
              <Link to="/clients" className="  ">
                Clients
              </Link>
            </li>
            <li className="pt-[2.22vh]">
              <Link to="/careers" className=" ">
                Careers
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col   lg:my-0 lg:mt-[3.96vh] lg:pr-[6.55vw]">
          <h2 className=" text-[5.3vw] lg:text-[1.25vw] font-semibold  font-montserrat text-[#22AD86]">
            GET IN TOUCH
          </h2>
          <div className="flex mt-[3vh] lg:mt-[4.44vh] space-x-6">
            <Link to="#" className="text-white items-center hover:text-[#22AD86] text-[4.5vh] lg:text-[3vh]">
              <BsYoutube />
            </Link>
            <Link to="#" className="text-white items-center hover:text-[#22AD86] text-[4.5vh] lg:text-[3vh]">
              <CgFacebook  />
            </Link>
            <Link to="#" className="text-white items-center hover:text-[#22AD86] text-[4.5vh] lg:text-[3vh]">
              <AiOutlineInstagram />
            </Link>
            <Link
              to="#"
              className="text-white items-center hover:text-[#22AD86] text-[4.5vh] lg:text-[3vh]"
            >
              <FaLinkedinIn  />
            </Link>
          </div>
        </div>
      </div>
      <div className="lg:mx-[6.17vw] border-t opacity-[40%] border-[#ffff] mt-[4vh] lg:mt-16 md:w-[87.0vw]"></div>
      <div className="py-[5vh] lg:py-0  lg:px-[7.29vw] flex lg:flex-row flex-col lg:pt-[2.78vh] lg:pb-[9.81vh]">
        <p className="text-white  font-montserrat text-[3.5vw] lg:text-[0.833vw] !opacity-100 ">
          Copyright 2024, RAT Films
        </p>
        <p className="hidden lg:block text-[0.833vw] opacity-[100%] px-2">|</p>
        <p className="text-white pt-[2vh] lg:pt-0 font-montserrat text-[3.5vw] lg:text-[0.833vw] ">
          Crafted by Togglehead
        </p>
      </div>
    </footer>
  );
}
