import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bgimg from '../../assets/AboutUs/film_texture___grain_explosion_by_stephanepellennec_d37pwfa-fullview.png';
import img1 from '../../assets/AboutUs/hotstar.png';
import img2 from '../../assets/AboutUs/jiocinema.png';
import img3 from '../../assets/AboutUs/pepsi.png';
import img4 from '../../assets/AboutUs/prime.png'; 
// import "./MultipleRows.scss";

const images = [
  img1, img2, img3, img4, img1, img2, img3, img4, 
  img1, img2, img3, img4, img1, img2, img3, img4, 
  img1, img2, img3, img4, img1
];

function MultipleRows() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,  // 4 columns
    slidesToScroll: 4, // Scroll 4 at a time
    rows: 3,          // 3 rows
    slidesPerRow: 1,  // 1 slide per row
    centerMode: false, // Disable center mode
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          rows: 4, 
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          rows: 4, 
          slidesToShow: 2,
          slidesToScroll:1,
        }
      }
    ]
  };

  return (
    <div className="  px-[3vw] md:px-0">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="flex  justify-center items-center md:pb-[1.30vw] cursor-pointer pb-[2vh] px-[2vw] lg:px-[0vw] "> 
          {/* <div className="inner-scratch"> */}
            <div
              className="w-[44.27vw] md:w-[20.68vw] h-[18.98vh] md:h-[26.11vh] flex justify-center items-center bg-cover bg-center overflow-hidden"
              style={{ backgroundImage: `url(${bgimg})` }}
            >
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className={`w-full h-full object-cover filter grayscale hover:grayscale-0 transition duration-300 ease-in-out 
                 `}
              />
            </div> 
            {/* </div> */}
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default MultipleRows;
