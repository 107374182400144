
import React from "react";
import Marquee from "react-fast-marquee";
import Heading from "../Highlightedheading/Heading";
import "./partner.scss"; // Import SCSS file

// Import your local images
import img_1 from "../../assets/Partners/1.png";
import img_2 from "../../assets/Partners/2.png";
import img_3 from "../../assets/Partners/4.png";
import img_4 from "../../assets/Partners/3.png";
import img_5 from "../../assets/Partners/Disney+_Hotstar_logo.svg.png";

export const Partners = () => {
  const images = [
    img_1,
    img_2,
    img_3,
    img_4,
    img_5,
    img_1,
    img_2,
    img_3,
    img_4,
    img_5,
  ];

  return (
    <div className="w-full h-full relative md:mt-[6.40vw] mt-[12vw]">
      <div className="outer-scratch relative w-[100vw] md:h-[50.30vh] h-[26.73vh] bg-no-repeat bg-cover bg-center z-20">
        <div className="inner-scratch">
          <div className="background grain">
            <div className="absolute z-20 top-0 md:pl-[7.29vw] px-4 py-4 md:py-0  md:pt-[7.5vh] ">
              <Heading headingText="OUR PARTNERS" />
            </div>

          

            <div className="absolute inset-0 flex justify-center items-center md:pt-[13.37vh]  z-30">
              <Marquee
                speed={60} 
                gradient={false}
                pauseOnHover={true}
                className="carousel-container"
              >
                {images.map((img, index) => (
                  <div
                    key={index}
                    className="flex justify-center items-center px-4 image-container "
                  >
                    <img
                      src={img}
                      alt={`Partner ${index}`}
                      className={`w-full h-full object-cover grayscale-img ${
                        index === 0 ? "grayscale-0 " : ""
                      }`}
                    />
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
