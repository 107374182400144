import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import { EffectCards, Autoplay, Navigation } from "swiper/modules";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./styles.css";

export default function SwiperSlide1({ images, dummyData, setContent }) {
  const swiperRef = useRef(null);

  function handleSlideChange(swiper) {
    const activeIndex = swiper.activeIndex;
    // console.log("Slide changed to index:", activeIndex);
    setContent(dummyData[activeIndex]);
  }

  function handlePrevClick() {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  }

  function handleNextClick() {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  }

  return (
    <div className="swiper-container">
      <Swiper
        effect={"cards"}
        grabCursor={true}
        centeredSlides={true}
        modules={[EffectCards, Autoplay, Navigation]}
        className="mySwiper"
        onSlideChange={handleSlideChange}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        // navigation={{
        //   prevEl: '.swiper-button-prev',
        //   nextEl: '.swiper-button-next',
        // }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <img src={img} alt={`Slide ${index}`} className="swiper-image" />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* Custom Navigation Buttons with Icons */}
      {/* <button className="swiper-button swiper-button-prev" onClick={handlePrevClick}>
        <FaChevronLeft size={24} />
      </button>
      <button className="swiper-button swiper-button-next" onClick={handleNextClick}>
        <FaChevronRight size={24} />
      </button> */}
    </div>
  );
}
