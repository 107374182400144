import React from "react";
import group from "./img/gourp.png"; // Ensure this path is correct
import Image32 from "./img/Image 32.png";
import FounderPersin from "./FounderPerson";
import Heading from "../../components/Highlightedheading/Heading";
import Pageheading from "../../components/PageHeading/Pageheading";
import { RiHome2Line } from "react-icons/ri";

const Founder = () => {
  return (
    <div>
      <div className="relative md:h-[85vh] h-[80vh] w-full text-white flex items-center ">
        <div className="absolute inset-0  opacity-75"></div>
        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center"
          style={{ backgroundImage: `url(${group})` }}
        ></div>
        <div className="absolute top-0 left-0 w-full  ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            height="358"
            viewBox="0 0 1920 358"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                x1="0.49"
                y1="0.884"
                x2="0.49"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopOpacity="0" />
                <stop offset="1" />
              </linearGradient>
            </defs>
            <rect
              id="Black_"
              data-name="Black "
              width="1920"
              height="358"
              opacity="0.85"
              fill="url(#linear-gradient)"
            />
          </svg>
        </div>
        <div className="relative z-10 flex flex-col md:flex-row justify-between w-full h-full px-4 lg:px-12 xl:px-16">
          <div className="flex pr-4 md:pl-36   flex-col justify-end md:justify-center items-center md:items-start w-full md:w-[40%] h-full">
            <Heading headingText={"FOUNDERS"} />
          </div>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent z-10 md:h-[72.2vh]">
            <div className="pl-[5.63vw] flex pt-[15vh] items-center md:pt-[19.85vh]">
              <Pageheading pageHeading={"Founders"} icon={<RiHome2Line />} />
            </div>
          </div>
          <div className="flex justify-center items-center  md:w-[60%] pt-10 md:pt-20  md:pr-16 lg:!overflow-y-hidden">
            <img src={Image32} alt="Founders" className="max-w-full w-[85.47vw] h-[32.24vh] md-full md:h-auto" />
          </div>
        </div>
      </div>

      <FounderPersin
        heading={true}
        flag={false}
        fName={"Ruchi"}
        lName={"Narain"}
        position={"( Director )"}
      /> 
      <div className="flex items-center justify-end">
      <div className="border-[1px] opacity-[15%] border-[#ffff]  md:w-[75%] md:ml-auto"></div>
      </div>
   

      <FounderPersin
        heading={false}
        flag={true}
        fName={"Ashutosh"}
        lName={"Shah"}
        position={"( Director )"}
      />

      <div className=" border-[1px] opacity-[15%] border-[#ffff]  md:w-[75%] md:mr-auto"></div>
      <FounderPersin
        heading={false}
        flag={false}
        fName={"Ruchi"}
        lName={"Narain"}
        position={"( Director )"}
      />
    </div>
  );
};

export default Founder;
