import React from "react";
import AboutPageHeading from "./AboutPageHeading";
import Award from "./Award";

export const Section5 = () => {
  return (
    <div className="md:pl-[6.77vw]">
      <div className="md:py-[5.56vh] ">
        <AboutPageHeading headingText="Awards" />
      </div>

      <div className="pl-[5vw] md:pl-0">
        <Award />
      </div>
    </div>
  );
};

export default Section5;
