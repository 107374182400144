import React from "react";

const GridImage = ({ images }) => {
  return (
<div className="grid md:grid-cols-3 grid-cols-2  md:mt-[2.08vw] w-full md:gap-[2.08vw] gap-[4vw]">
  <div className=" md:h-[50vh] h-[19.9vh] cursor-pointer transition-transform transform hover:scale-105 duration-300 ease-in-out">
    <div className="h-full w-full">
      <img
        src={`/Gallery_img/${images[0]}.png`}
        alt={`Img ${images[0]}`}
        className="h-full w-full object-cover"
      />
    </div>
  </div>
  <div className=" md:h-[50vh] h-[19.9vh] transition-transform transform hover:scale-105 duration-300 ease-in-out">
    <div className="h-full w-full">
      <img
        src={`/Gallery_img/${images[1]}.png`}
        alt={`Img ${images[1]}`}
        className="h-full w-full object-cover"
      />
    </div>
  </div>
  {/* Updated this div to span 2 columns below medium breakpoint */}
  <div className="md:row-span-2 md:col-span-1 col-span-2 w-full h-[19.9vh] md:h-full  bg-slate-700 transition-transform transform hover:scale-105 duration-300 ease-in-out">
    <div className="h-full w-full">
      <img
        src={`/Gallery_img/${images[2]}.png`}
        alt={`Img ${images[2]}`}
        className="h-full w-full object-cover"
      />
    </div>
  </div>
  <div className="col-span-2  md:h-[50vh] pb-[4vw] md:pb-0 h-[19.9vh] transition-transform transform hover:scale-105 duration-300 ease-in-out">
    <div className="h-full w-full">
      <img
        src={`/Gallery_img/${images[3]}.png`}
        alt={`Img ${images[3]}`}
        className="h-full w-full object-cover"
      />
    </div>
  </div>
</div>


  );
};

export default GridImage;
