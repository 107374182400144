import React, { useState } from "react";
import Heading from "../Highlightedheading/Heading";
import Button from "../Buttons/Button";
import CustomPlaybutton from "../Buttons/CustomPlaybutton";

import { useNavigate } from "react-router-dom";
import "./tvcs.css";
import MobileSlider from "../CostomSlider/VideoSlider";

const Tvcs = ({videos}) => { 
   
  const thumbnail_1 = videos[0]?.[0]?.thumbnail || '';
  const thumbnail_2 = videos[0]?.[1]?.thumbnail || '';
  const thumbnail_3 = videos[0]?.[2]?.thumbnail || '';
  const thumbnail_4 = videos[0]?.[3]?.thumbnail || '';
  const thumbnail_5 = videos[0]?.[4]?.thumbnail || '';
  const thumbnail_6 = videos[0]?.[5]?.thumbnail || '';
  const thumbnail_7 = videos[0]?.[6]?.thumbnail || '';
  const thumbnail_8 = videos[0]?.[7]?.thumbnail || '';

  const videoId_1 = videos[0]?.[0]?.videoId || '';
  const videoId_2 = videos[0]?.[1]?.videoId || '';
  const videoId_3 = videos[0]?.[2]?.videoId || '';
  const videoId_4 = videos[0]?.[3]?.videoId || '';
  const videoId_5 = videos[0]?.[4]?.videoId || '';
  const videoId_6 = videos[0]?.[5]?.videoId || '';
  const videoId_7 = videos[0]?.[6]?.videoId || '';
  const videoId_8 = videos[0]?.[7]?.videoId || '';
 

  const [hoveredVideo, setHoveredVideo] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = (videoId) => {
    setHoveredVideo(videoId);
  };

  const handleMouseLeave = () => {
    setHoveredVideo(null);
  };

  const handlePlayClick = (videoId) => {
    console.log(videoId, "clicked");
    navigate(`/watch/${videoId}`);
  };

  console.log("videos",videos);

  const renderIframe = (url, thumbnail, videoId, width, height) => (
    <div
      className="relative shadowanimation"
      style={{ width, height }}
      onMouseEnter={() => handleMouseEnter(videoId)}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => handlePlayClick(videoId)}
    >
      <img
        src={thumbnail}
        alt={`Thumbnail for video ${videoId}`}
        className="absolute top-0 left-0 w-full h-full object-cover"
      />
      {hoveredVideo !== videoId && (
        <div className="flex w-full items-center justify-center h-full">
          <CustomPlaybutton onClick={() => handlePlayClick(videoId)} />
        </div>
      )}
      {hoveredVideo === videoId && (
        <div className="vimeo-full-width relative ">
          <iframe
            src={`${url}?autoplay=1&loop=1&muted=1&controls=0`}
            className="absolute top-0 scale-135 left-0 w-full h-full cursor-pointer"
            title={`Video ${videoId}`}
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
          <div
            className="absolute top-0 left-0 w-full h-full cursor-pointer"
            style={{ zIndex: 1 }}
            onClick={() => handlePlayClick(videoId)}
          ></div>
        </div>
      )}
    </div>
  );

  return (
    <section className="w-full h-full">
      <div className="flex items-center justify-between md:px-[7.29vw] mx-4 md:pt-[18.65vh] py-[2.70vh] md:pb-3 relative">
        <Heading headingText="TVCS" />
        <Button
          buttonText={"VIEW ALL"}
          customCss={"md:pt-8"}
          onClick={() => navigate("/commercial")}
        />
      </div>
   {
    videos.map((video)=>(

   
    <div className="w-full md:grid grid-rows-1 md:grid-cols-[37%_63%] gap-[1.46vw] overflow-x-hidden md:px-0 px-4 md:py-2  hidden" 
    key={video.videoId}
    >
        {/* Left Side */} 

        <div className="flex flex-col gap-[1.20vw]">
          <div className="w-full md:w-[37.20vw] flex items-center justify-center  h-[60.200vh] shadowanimation2 hover:rounded-md">
            <div className="w-full md:w-[36.60vw] flex items-center justify-center   h-[58.50vh] shadowanimation1 hover:rounded-md">
              <div className="w-full md:w-[36.51vw] h-[58.33vh] opacity-100  hover:rounded-md">
                {renderIframe(
                  `https://player.vimeo.com/video/${videoId_2}`,
                  thumbnail_2,
                  `${videoId_2}`,
                  "100%",
                  "100%"
                )}
              </div>
            </div>
          </div>

          <div className="w-full md:w-[37.20vw] flex items-center justify-center h-[37vh] shadowanimation2 hover:rounded-md">
            <div className="w-full md:w-[36.60vw] flex items-center justify-center h-[36.60vh] shadowanimation1 hover:rounded-md">
              <div className="w-full md:w-[36.51vw] h-[36.39vh] shadowanimation overflow-hidden hover:rounded-md">
              {renderIframe(
                  `https://player.vimeo.com/video/${videoId_3}`,
                  thumbnail_3,
                  `${videoId_3}`,
                  "100%",
                  "100%"
                )}
              </div>
            </div>
          </div>

          <div className="w-full md:w-[37.20vw] flex items-center justify-center h-[59.200vh] shadowanimation2 hover:rounded-md">
            <div className="w-full md:w-[36.60vw] flex items-center justify-center h-[58.40vh] shadowanimation1 hover:rounded-md">
              <div className="w-full md:w-[36.51vw] h-[58.33vh] shadowanimation overflow-hidden hover:rounded-sm">
              {renderIframe(
                  `https://player.vimeo.com/video/${videoId_4}`,
                  thumbnail_4,
                  `${videoId_4}`,
                  "100%",
                  "100%"
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[1.46vw] pt-[1vh]">
          <div className="flex flex-col gap-y-[1.46vw]">
            <div className="w-full flex items-center justify-center  h-[44vh] shadowanimation2 hover:rounded-md">
              <div className="w-full flex items-center justify-center h-[43.59vh] shadowanimation1 hover:rounded-md">
                <div className="w-full h-[43vh] hove:scale-90  shadowanimation overflow-hidden hover:rounded-sm">
                {renderIframe(
                  `https://player.vimeo.com/video/${videoId_5}`,
                  thumbnail_5,
                  `${videoId_5}`,
                  "100%",
                  "100%"
                )}
                </div>
              </div>
            </div>

            <div className="w-full flex items-center  justify-center h-[51.20vh] shadowanimation2 hover:rounded-md">
              <div className="w-full flex items-center justify-center h-[50.80vh] shadowanimation1 hover:rounded-md">
                <div className="w-full h-[50.20vh]  shadowanimation overflow-hidden hover:rounded-sm">
                {renderIframe(
                  `https://player.vimeo.com/video/${videoId_6}`,
                  thumbnail_6,
                  `${videoId_6}`,
                  "100%",
                  "100%"
                )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-[1.46vw] h-[44vh] shadowanimation2 hover:rounded-md">
            <div className="w-full flex items-center justify-center h-[43.90vh] shadowanimation1 hover:rounded-md">
              <div className="w-full flex items-center justify-center h-[43.59vh] shadowanimation hover:rounded-sm">
         
              {renderIframe(
                  `https://player.vimeo.com/video/${videoId_7}`,
                  thumbnail_7,
                  `${videoId_7}`,
                  "100%",
                  "100%"
                )}
               
              </div>
            </div>

            <div className="w-full flex items-center justify-center h-[52vh] shadowanimation2 hover:rounded-md">
              <div className="w-full flex items-center justify-center h-[51.40vh] shadowanimation1 hover:rounded-md">
                <div className="w-full h-[51vh] shadowanimation overflow-hidden hover:rounded-sm">
                {renderIframe(
                  `https://player.vimeo.com/video/${videoId_8}`,
                  thumbnail_8,
                  `${videoId_8}`,
                  "100%",
                  "100%"
                )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex items-center md:w-[62.05vw] justify-center h-[58.7vh] shadowanimation2 hover:rounded-md">
            <div className="w-full flex items-center md:w-[62.05vw] justify-center h-[57.9vh] shadowanimation1 hover:rounded-md">
              <div className="col-span-2 row-span-2 w-full md:w-[62.05vw] h-[57.7vh] shadowanimation overflow-hidden hover:rounded-sm">
              {renderIframe(
                  `https://player.vimeo.com/video/${videoId_1}`,
                  thumbnail_1,
                  `${videoId_1}`,
                  "100%",
                  "100%"
                )}
              </div>
            </div>
          </div>
        </div>
      </div> 
    ))
   }
   
      <div className="block lg:hidden">
        <MobileSlider />
      </div>
    </section>
  );
};

export default Tvcs;
