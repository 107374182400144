import React from "react";

const Heading = ({ headingText, className="" }) => {
  return (
    <h1 className={`gradient-text  md:text-[5.5vw] text-[11vw] flex flex-wrap  text-center tracking-normal brightness-125    font-bebas_neue  uppercase  ${className}`}>
      {headingText}
    </h1>
  );
};

export default Heading;
