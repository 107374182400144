import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bgimg from '../../assets/AboutUs/film_texture___grain_explosion_by_stephanepellennec_d37pwfa-fullview.png';
import img1 from '../../assets/AboutUs/hotstar.png';
import img2 from '../../assets/AboutUs/jiocinema.png';
import img3 from '../../assets/AboutUs/pepsi.png';
import img4 from '../../assets/AboutUs/prime.png';

const images = [
  img1, img2, img3, img4, img1, img2, img3, img4, 
  img1, img2, img3, img4, img1, img2, img3, img4, 
  img1, img2, img3, img4, img1, img2, img3, img4
];

export function ClientSection2() {
  const [gap, setGap] = useState(40); // Default gap

  // Update gap based on window size
  useEffect(() => {
    const updateGap = () => {
      if (window.innerWidth < 480) {
        setGap(1); // Smaller gap on extra small screens
      } else if (window.innerWidth < 768) {
        setGap(1); // Medium gap on small screens
      } else if (window.innerWidth < 1280) {
        setGap(20); // Larger gap on medium screens
      } else {
        setGap(40); // Default gap on large screens
      }
    };

    updateGap(); // Set initial gap
    window.addEventListener("resize", updateGap); // Update gap on resize

    // Cleanup listener
    return () => {
      window.removeEventListener("resize", updateGap);
    };
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,  // 4 columns on large screens
    slidesToScroll: 4, // Scroll 4 at a time
    rows: 4,          // 4 rows
    slidesPerRow: 1,  // 1 slide per row
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,  // 3 columns on medium screens
          slidesToScroll: 3,
          rows: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,  // 2 columns on small screens
          slidesToScroll: 2,
          rows: 6,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,  // 1 column on extra small screens
          slidesToScroll: 2,
          rows: 6,
        }
      }
    ]
  };

  return (
    <div className="px-4 py-4">
      <Slider
        {...settings}
        style={{
          margin: `0 -${gap}px`,  // Responsive margin for .slick-list
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              padding: `0 ${gap}px`,  // Responsive padding for .slick-slide
            }}
            className="flex justify-center items-center p-[5vw] md:p-7"
          >
            <div
              className="w-[40.27vw] h-[18.93vh] lg:w-[20.68vw] lg:h-[26.11vh] flex justify-center items-center bg-cover bg-center overflow-hidden"
              style={{ backgroundImage: `url(${bgimg})` }}
            >
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-full object-cover filter grayscale hover:grayscale-0 transition duration-300 ease-in-out"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ClientSection2;
