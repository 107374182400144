import React from "react";
import { useForm, Controller } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiHome2Line } from "react-icons/ri";

import contactImg from "../career/img/Careers_bg.png";
import texture from "../career/img/texture.png";
import BasicTextFields from "../../components/textfield/textField";
import Heading from "../../components/Highlightedheading/Heading";
import Pageheading from "../../components/PageHeading/Pageheading";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import Button from "../../components/Buttons/Button";
import InputFileUpload from "../../components/FileUpload/InputFileUpload"; 

const Career = () => {
  // Initialize react-hook-form with default values
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      Last_name: '',
      email: '',
      contactDetails: '',
      experience: '',
      location: '',
      resume: null,
      coverLetter: null,
    }
  });

  // Handle form submission
  const onSubmit = (data) => {
    // Log form data to the console
    console.log("Form data:", data);

    // Show success toast notification
    toast.success("Form submitted successfully!");

    // Reset the form fields
    reset();
  };

  return (
    <div className="w-full h-full  text-white relative !overflow-hidden">
      {/* Header Section */}
      <div className="relative w-full md:h-[72.8vh] h-[77vh] flex items-center overflow-hidden">
  <div
    className="absolute inset-0 bg-black bg-cover bg-no-repeat bg-center flex justify-center items-center z-0"
    style={{
      backgroundImage: `url(${contactImg})`,
      backgroundSize: "cover",  
      backgroundPosition: "right center", //
      width: "100%",
      height: "100%",
    }}
  >
    <Heading headingText={"Careers"} />
  </div>
  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent z-10 h-[93dvh]">
    <div className="md:pl-[5.63vw] pl-[3vw] flex items-center md:pt-[21vh] pt-[14vh] ">
      <Pageheading pageHeading={"Careers"} icon={<RiHome2Line />} />
    </div>
  </div>
</div>

      {/* Introduction Section */}
      <div className=" w-full px-4 md:px-0 flex flex-col md:ml-[6.77vw]">
      <div className="pt-[5.56vh] md:pt-0 w-10/12 md:w-full">
        <SectionHeading
          sectionheading={"INTERESTED IN BEING ONE OF US?"}
          CustomCss={"md:pt-[8.33vh] md:pb-[5.56vh]"}
        /> 
         </div>

        <div className="md:w-[67.7vw] md:pb-[7.81vh] w-full py-[4vh] md:py-0 md:text-[1.15vw] text-[4.27vw] font-light tracking-normal font-montserrat md:leading-[1.88vw] ">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem
        </div>
      </div>

      {/* Form Section */}
      <div
        className="relative flex flex-col w-full"
        style={{
          backgroundImage: `url(${texture})`,
          backgroundPosition: "0% 0%",
          backgroundSize: "cover",
        }}
      >
        <div className="text-center outer-scratch">
          <div className="inner-scratch">
            <div className="background grain">
              <h2 className="text-white md:py-[9.26vh] py-[3.70vh] text-[12.8vw] md:text-[3.54vw] font-bebas_neue tracking-normal uppercase brightness-125">
                APPLY NOW
              </h2>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col items-center px-4 md:pb-[16.2vh] md:px-[7.29vw] space-y-[3.70vh] w-full"
              >
                {/* Personal Information Fields */}
                <div className="flex flex-col md:flex-row md:space-x-10 space-y-[3.70vh] md:space-y-0 w-full">
                  {/* Name Field */}
                  <div className="flex flex-1 items-center">
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: "Name is required" }}
                      render={({ field }) => (
                        <BasicTextFields
                          {...field}
                          text="Name*"
                          error={errors.name?.message}
                        />
                      )}
                    />
                  </div> 

                  {/* Last Name Field */}
                  <div className="flex flex-1 items-center">
                    <Controller
                      name="Last_name"
                      control={control}
                      rules={{ required: "Last Name is required" }}
                      render={({ field }) => (
                        <BasicTextFields
                          {...field}
                          text="Last Name*"
                          error={errors.Last_name?.message}
                        />
                      )}
                    />
                  </div>

                  {/* Email Field */}
                  <div className="flex flex-1 items-center">
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: /^[^@]+@[^@]+\.[^@]+$/,
                          message: "Invalid email address",
                        },
                      }}
                      render={({ field }) => (
                        <BasicTextFields
                          {...field}
                          text="Email Id*"
                          error={errors.email?.message}
                        />
                      )}
                    />
                  </div>
                </div>

                {/* Contact and Experience Fields */}
                <div className="flex flex-col md:flex-row md:space-x-10 space-y-[3.70vh] md:space-y-0  w-full">
                  {/* Contact Details Field */}
                  <div className="flex flex-1 items-center">
                    <Controller
                      name="contactDetails"
                      control={control}
                      rules={{ required: "Contact is required" }}
                      render={({ field }) => (
                        <BasicTextFields
                          {...field}
                          text="Contact No*"
                          error={errors.contactDetails?.message}
                        />
                      )}
                    />
                  </div>

                  {/* Experience Field */}
                  <div className="flex flex-1 items-center">
                    <Controller
                      name="experience"
                      control={control}
                      rules={{ required: "Experience is required" }}
                      render={({ field }) => (
                        <BasicTextFields
                          {...field}
                          text="Experience*"
                          error={errors.experience?.message}
                        />
                      )}
                    />
                  </div>

                  {/* Location Field */}
                  <div className="flex flex-1 items-center">
                    <Controller
                      name="location"
                      control={control}
                      rules={{ required: "Location is required" }}
                      render={({ field }) => (
                        <BasicTextFields
                          {...field}
                          text="Where are you based*"
                          error={errors.location?.message}
                        />
                      )}
                    />
                  </div>
                </div>

                {/* File Upload Fields */}
                <div className="flex flex-col md:flex-row md:space-x-10 space-y-[3.70vh] md:space-y-0 w-full">
                  {/* Resume Upload Field */}
                  <div className="flex flex-1 flex-col pt-3">
                    <Controller
                      name="resume"
                      control={control}
                      rules={{ required: "Resume is required" }}
                      render={({ field }) => (
                        <InputFileUpload
                          text="Resume upload*"
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    />
                    {errors.resume && (
                      <p className="text-red-500 mt-2">{errors.resume.message}</p>
                    )}
                  </div>

                  {/* Cover Letter Upload Field */}
                  <div className="flex flex-1 flex-col pt-2">
                    <Controller
                      name="coverLetter"
                      control={control}
                      rules={{ required: "Cover letter is required" }}
                      render={({ field }) => (
                        <InputFileUpload
                          text="Cover Letter*"
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    />
                    {errors.coverLetter && (
                      <p className="text-red-500 mt-2">
                        {errors.coverLetter.message}
                      </p>
                    )}
                  </div>

                  {/* Submit Button */}
                  <div className="flex-1 md:pt-3 md:pl-6 py-[3.70vh] md:py-0 flex items-center justify-start">
                    <Button buttonText={"Submit"} type="submit"  />
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* Toast Container for Notifications */}
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default Career;
