import React from "react";

const ReversedSection = ({ imgSrc, leftMediaText, rightMediaText }) => {
  return (
    <div className="w-full md:pl-[10rem] 2xl:pl-[19.56rem]  py-10 relative">
      <div className="w-full flex flex-col md:flex-row-reverse items-center justify-center ">
        {/* Right side (previously the left side in Mediasection) */}

        <div className="outer-scratch md:w-[32.08vw] w-[91.47vw] md:h-[25.70vh] flex flex-col items-center justify-center bg_group_1606  md:py-4 py-8">
          <div className="background grain ">
            <div className="flex items-center justify-center">
              <img
                src={imgSrc}
                alt=""
                className="md:w-[5.36vw]  w-[21.33vw]  object-contain p-2"
              />
            </div>
            <p className=" w-[90vw] lg:w-[22rem] h-auto lg:h-[5.6875rem] text-center text-white opacity-100 font-bebas_neue md:text-[2.08vw] text-[8.53vw] tracking-normal ">
              {leftMediaText}
            </p>
          </div>
        </div>

        {/* Left side (previously the right side in Mediasection) */}
        <div className="px-[24px] md:px-0 py-[32px] w-[91.47vw] h-auto sm:min-h-[152px] md:w-[39.96vw] md:h-[25.68vh] flex flex-col  justify-center md:py-4 border-[1px] border-gray-100 border-solid border-opacity-30">
          <p className="text-left font-light md:text-[1.15vw] text-[5.33vw] lg:leading-[2rem] tracking-normal font-montserrat text-gray-100   lg:pr-[8.13rem] xl:pr-[8rem] 2xl:pr-[15.1875rem] md:pl-[3.5625rem] overflow-hidden">
            {rightMediaText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReversedSection;
