import React from "react";

const CustomPlaybutton = ({ onClick }) => {
  const handleClick = (event) => {
    event.stopPropagation();
    console.log("button Clicked");
    onClick();
  };

  return (
    <button
      className="text-center flex items-center justify-center md:w-[8vw] relative w-28 h-full p-4 z-10"
      onClick={handleClick}
    >
      <img src="/icon/Play.svg" alt="Play button" className="w-full h-auto" />
    </button>
  );
};

export default CustomPlaybutton;
