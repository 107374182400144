// eslint-disable-next-line no-unused-vars
import React from "react";
import { Link } from "react-router-dom";
import contactimg from "./img/contactimg.png";
import Heading from "../../components/Highlightedheading/Heading";
import Pageheading from "../../components/PageHeading/Pageheading";
import { RiHome2Line } from "react-icons/ri";

const Media = () => {
  const arr = [1, 2, 3, 4, 5, 6];

  return (
    <div className="w-full h-full bg-[#0F221C] text-white relative">
      <div className="relative w-full h-[77vh] md:h-[60vh]  flex items-center overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-black to-transparent opacity-75"></div>
        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center flex justify-center items-center"
          style={{ backgroundImage: `url(${contactimg})` }}
        >
          <Heading text={"Media"} />
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent z-10 h-[93dvh]">
          <div className="md:pl-[5.63vw] flex items-center md:pt-[21.85vh]">
            <Pageheading pageHeading={"Media"} icon={<RiHome2Line />} />
          </div>
        </div>
      </div>

      {/* ******************************************************************************* */}

      <div className="w-full p-6 md:p-12 lg:p-16 xl:p-24">
        <div className="py-6 md:py-10">
          <Heading text={"TRENDING NOW"} />
        </div>

        <div className="relative flex flex-col md:flex-row justify-center gap-8 items-center w-full">
          <div
            className="relative bg-cover bg-no-repeat bg-center flex justify-center items-center w-full h-64 md:w-1/2 md:h-80 lg:h-[350px] xl:h-[670px]"
            style={{ backgroundImage: `url(${contactimg})` }}
          >
            <div className="absolute flex h-full flex-col p-4 md:p-12 space-y-4 md:space-y-6 justify-end">
              <h2>TRENDING</h2>
              <p className="w-[85%] text-gray-300 font-semibold text-lg">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro
                et autem vero dolorem veniam, adipisci alias voluptatum
                dignissimos debitis natus vitae ad non ratione ex voluptates
                culpa minus voluptatibus. Autem.
              </p>
              <p>15 Feb 2024</p>
            </div>
          </div>
          <div className="relative flex flex-col gap-8 items-center w-full md:w-1/2">
            <div
              className="relative bg-cover bg-no-repeat bg-center flex justify-center items-center w-full h-64 md:h-1/2 lg:h-80"
              style={{ backgroundImage: `url(${contactimg})` }}
            >
              <div className="absolute flex h-full flex-col p-4 md:p-8 space-y-2 justify-end">
                <h2>TRENDING</h2>
                <p className="w-[85%]">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro
                  et autem vero dolorem veniam, adipisci alias voluptatum
                  dignissimos debitis natus vitae ad non ratione ex voluptates
                  culpa minus voluptatibus. Autem.
                </p>
                <p>15 Feb 2024</p>
              </div>
            </div>
            <div
              className="relative bg-cover bg-no-repeat bg-center flex justify-center items-center w-full h-64 md:h-1/2 lg:h-80"
              style={{ backgroundImage: `url(${contactimg})` }}
            >
              <div className="absolute flex h-full flex-col p-4 md:p-8 space-y-2 justify-end">
                <h2>TRENDING</h2>
                <p className="w-[85%]">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro
                  et autem vero dolorem veniam, adipisci alias voluptatum
                  dignissimos debitis natus vitae ad non ratione ex voluptates
                  culpa minus voluptatibus. Autem.
                </p>
                <p>15 Feb 2024</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/************************************************************************************/}

      <div className="w-full p-6 md:p-12 lg:p-16 xl:p-24">
        <h1 className="py-4 text-3xl md:text-4xl lg:text-5xl">LATEST</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 w-full  items-center flex-row justify-center">
          {arr.map((item, index) => (
            <div
              key={index}
              className="max-w-xs sm:max-w-sm lg:max-w-md text-white border mx-auto border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
            >
              <Link to="#">
                <img
                  className="rounded-t-lg w-full h-48 object-cover"
                  src={contactimg}
                  alt=""
                />
              </Link>
              <div className="p-4 md:p-5">
                <Link to="#">
                  <h5 className="mb-2 text-xl md:text-2xl font-bold tracking-tight dark:text-white">
                    Noteworthy technology acquisitions 2021
                  </h5>
                </Link>
                <p className="mb-3 text-sm md:text-base font-normal">
                  Here are the biggest enterprise technology acquisitions of
                  2021 so far, in reverse chronological order.
                </p>
                <Link
                  to="#"
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Read more
                  <svg
                    className="w-4 h-4 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Media;
