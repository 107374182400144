import React from 'react';
import SimpleSlider from './SimpleSlider'; // Adjust the path if necessary

const Section1 = () => {
  return (
    <div>
      <SimpleSlider />
    </div>
  );
}

export default Section1;
