import React from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import thumb_1 from "../../assets/TVCS_Video/video_1_files/FENA.dcf1daa7.png";
import thumb_2 from "../../assets/TVCS_Video/video_1_files/Birthday-Party-thumbnail.2d17eb0e.png";
import thumb_3 from "../../assets/TVCS_Video/video_1_files/DIVIDE.07a3493d.png";
import thumb_4 from "../../assets/TVCS_Video/video_1_files/Innovative-Friend.372ed62b.png";
import thumb_5 from "../../assets/TVCS_Video/video_1_files/Airy-Updo.30a5b5ef.jpg";
import thumb_6 from "../../assets/TVCS_Video/video_1_files/Cafe.55a67dbd.png";
import thumb_7 from "../../assets/TVCS_Video/video_1_files/beti-film-70.550add3f.jpg";
import thumb_8 from "../../assets/TVCS_Video/video_1_files/Daddy-No1.b7308bf5.png";
import CustomPlaybutton from "../Buttons/CustomPlaybutton";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-next-arrow absolute right-1 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer"
      onClick={onClick}
    >
      <FiChevronRight size={30} color="#ffff" /> {/* Custom right icon */}
    </div>
  );
};

// Custom Previous Arrow
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-prev-arrow absolute left-1 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer"
      onClick={onClick}
    >
      <FiChevronLeft size={30} color="#ffff" />
    </div>
  );
};
const videos = [
    { id: 1, videoId: "293940724", thumbnail: thumb_1 },
    { id: 2, videoId: "291681025", thumbnail: thumb_2 },
    { id: 3, videoId: "333499420", thumbnail: thumb_3 },
    { id: 4, videoId: "291681025", thumbnail: thumb_4 },
    { id: 5, videoId: "293940724", thumbnail: thumb_5 },
    { id: 6, videoId: "276703567", thumbnail: thumb_6 },
    { id: 7, videoId: "123456789", thumbnail: thumb_7 },
    { id: 8, videoId: "987654321", thumbnail: thumb_8 },
  ];

export function Responsive() {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="h-[50vh] mx-auto px-2 pt-0 overflow-x-auto">
      <Slider {...settings}>
        {videos.map((item) => (
          <div key={item.id} className="p-1 w-[91.47vw] lg:w-[40vw] relative group">
            <div className="bg-white h-[40.19vh] overflow-hidden shadow-lg relative">
              <img
                src={item.thumbnail}
                alt={`Thumbnail ${item.id}`}
                className="w-full h-full object-cover"
              /> 
              {/* Play icon centered */}
              <div
                className="absolute inset-0 flex justify-center items-center cursor-pointer z-30"
                onClick={() => { navigate(`/watch/${item.videoId}`); }}
              >
              <CustomPlaybutton onClick={()=> { navigate(`/watch/${item.videoId}`); }}/>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Responsive;
