import React, { useState, useCallback } from "react";
import ReactPlayer from "react-player/vimeo";

const HoverPlayer = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleVideoLoad = useCallback(() => {
    console.log("Video has loaded successfully.");
    setIsVideoLoaded(true);
  }, []);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
className="relative w-full h-screen"
    >
      {/* Container to maintain aspect ratio */}
      <div className="relative w-full pb-[56.25%]">
        {/* Thumbnail Image */}
        <img
          src="/images/2.png" // Replace with your thumbnail URL
          alt="Video Thumbnail"
          className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-500 ${
            isHovered || isVideoLoaded ? "opacity-0" : "opacity-100"
          }`}
          onLoad={() => console.log("Thumbnail image loaded.")}
        />

        {/* ReactPlayer Vimeo Video */}
        <ReactPlayer
          url="https://vimeo.com/538105209"
          playing={isHovered}
          loop
          muted
          onReady={handleVideoLoad}
          width="100%"
          height="100%"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            transition: "opacity 0.5s ease",
            opacity: isHovered || isVideoLoaded ? 1 : 0,
            pointerEvents: isHovered ? "auto" : "none", // Allow interaction only when hovered
          }}
        />
      </div>
    </div>
  );
};

export default HoverPlayer;
