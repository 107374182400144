import React from "react";
import contactImg from "../career/img/career.png";
import Pageheading from "../../components/PageHeading/Pageheading";
import { RiHome2Line } from "react-icons/ri";
import Mediasection from "../../components/services/Mediasection";
import ReversedSection from "../../components/services/ReversedSection.jsx";
import Heading from "../../components/Highlightedheading/Heading.jsx";

const Services = () => {
  return (
    <div className="w-full h-full  text-white">
      <div className="relative w-full h-[77vh] flex items-center overflow-hidden ">
        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center flex justify-center items-center z-0"
          style={{
            backgroundImage: `url(${contactImg})`,
            backgroundColor: "transparent",
            width: "100vw",
            height: "72.81vh",
          }}
        >
          <Heading headingText={"SERVICES"} />
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent z-10 h-[93dvh]">
          <div className="pl-[8.63vw] md:pl-[5.63vw] flex items-center pt-[25.29vw] md:pt-[10.29vw]">
            <Pageheading pageHeading={"Services"} icon={<RiHome2Line />} />
          </div>
        </div>
      </div>

      <Mediasection
        customCSs={"md:pt-[8.24vh]"}
        imgSrc="/images/Group 1707.png"
        leftMediaText="Complete Film and Media Production Services"
        rightMediaText="We are one of the leading Film production companies in India."
      />

      <ReversedSection
        imgSrc="/images/Group 1704.png"
        leftMediaText="Creative Lab"
        rightMediaText="Content creation for the brand. An innovative Hybrid model where we break the barriers by bringing the best innovative creative minds to generate some really cool ideas to tell your brand's stories."
      />

      <Mediasection
        imgSrc="/images/Group 1705.png"
        leftMediaText="Line Production and Project Management"
        rightMediaText="We offer Industries best line production services with a total turn key solutions. Getting the best crew possible for the project to execute it seamlessly and without any hassles."
      />

      <ReversedSection
        imgSrc="/images/Group 1706.png"
        leftMediaText="360 Degree Media Innovation"
        rightMediaText="Strategising and Innovating a comprehensive media possibilities. Creating Unique Brand Touch points and creating HIIT ( High Impact Innovative Targets ) to maximise your brand exposure"
        reverse
      />
      <Mediasection
        customCSs={"md:pb-[9.26vh]"}
        imgSrc="/images/Group 1707.png"
        leftMediaText="Brand Development"
        rightMediaText="We help you build your brand. Be it scaling it up or launching it in the best possible way."
      />
    </div>
  );
};

export default Services;
