import React from 'react';
import img1 from '../../assets/AboutUs/pexels-teddy-joseph-2955375@2x.png';
import Responsive from './TeamRATSlider';
import AboutPageHeading from './AboutPageHeading';

const TeamRAT = () => {
  const images = [img1, img1, img1, img1, img1]; // Array of images

  return (
    <div className="md:pb-[5.56vh] md:pl-[6.77vw] md:pr-[4.58vw] ">
      <div className="">
       <AboutPageHeading headingText="Team R.A.T" />
      </div>
      <div className='md:pt-[5.56vh] px-[2vw]'>
        <Responsive images={images}  fName={"Jack"}
        lName={"Johansan"}
        position={"(Assistant Director )"}/>
      </div>
    </div>
  );
}

export default TeamRAT;
